import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import Layout from 'components/Order/Layout';
import Button from 'components/UI/Button';
import Auth from 'components/Auth';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { PATH_RECEIVER } from 'utils/constants/paths.js';
import TitleName from 'components/UI/Header/TitleName';

const mixinMemberList = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const mixinMember = css`
  flex: 0 0 calc((100% - 12px) / 2);
  height: 129px;
  border-radius: 8px;

  @media (max-width: ${md}) {
    flex: 0 0 100%;
    height: 149px;
  }
`;

const Container = styled.div`
  @media (max-width: ${md}) {
    padding: 0 16px 120px;
  }

  .skeleton-member-list-wrapper {
    ${mixinMemberList};

    .skeleton-member-list {
      ${mixinMember};
    }
  }
`;

const Btn = styled(Button)`
  width: 114px;
  height: 38px;
  color: #3b3516;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
`;

const AddBtn = styled(Btn)`
  @media (max-width: ${md}) {
    width: 100%;
    height: 60px;
    background-color: #5fd2da;
    border: 1px solid #5fd2da;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
`;

const EditBtn = styled(Btn)`
  width: 64px;
  height: 32px;
  background-color: #eafeff;
  border: 1px solid #5fd2da;
  font-size: 16px;
  font-weight: 400;
  color: #214a4c;

  @media (max-width: ${md}) {
    font-size: 14px;
  }
`;

const DeleteBtn = styled(Btn)`
  width: 64px;
  height: 32px;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 14px;
  }
`;

const MemberList = styled.div`
  ${mixinMemberList};
`;

const Member = styled.div`
  ${mixinMember};
  background-color: #ffffff;
  border: 1px solid #5fd2da;
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  gap: 8px 0px;

  @media (max-width: ${md}) {
    padding: 16px;
  }
`;

const MemberRow = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #868686;
  display: flex;

  @media (max-width: ${md}) {
    font-size: 14px;
    color: #3b3516;
  }

  &:nth-child(1) {
    justify-content: space-between;
  }

  &:nth-child(2) {
    @media (max-width: ${md}) {
      height: 48px;
    }
  }

  &:nth-child(3) {
    justify-content: flex-end;
  }
`;

const MemberName = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 16px;
  }
`;

const MemberBtnGroup = styled.div`
  display: flex;
  gap: 0px 8px;
`;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 108px;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    z-index: 1000;
    padding: 24px 15px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    flex: 1;
  }
`;

const ReceiverList = (props) => {
  const pageTitle = '收件人管理';
  const api = useAPI();

  const [addressList, setAddressList] = useState();

  //取得已儲存地址列表
  const getAddressList = () => {
    api
      .getAddress()
      .then((res) => {
        setAddressList(res.rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //刪除收件人
  const deleteAddress = (addressId) => {
    api
      .deleteAddress({ addressId })
      .then((res) => {
        alert('刪除成功');
        getAddressList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickEdit = (item) => {
    navigate(`${PATH_RECEIVER}/edit`, {
      state: { addressId: item.addressId },
    });
  };

  const handleClickDelete = (item) => {
    confirm('是否確定刪除該筆資料？').then(() => {
      deleteAddress(item.addressId);
    });
  };

  useEffect(() => {
    getAddressList();
  }, []);

  return (
    <Layout {...props} pageTitle={pageTitle}>
      <TitleName />
      <Auth />
      <Container>
        <TitleBar>
          <Title>{pageTitle}</Title>
          <BtnGroup>
            <AddBtn onClick={() => navigate(`${PATH_RECEIVER}/add`)}>
              新增
            </AddBtn>
          </BtnGroup>
        </TitleBar>
        {addressList !== undefined ? (
          <MemberList>
            {addressList?.map((item) => {
              return (
                <Member>
                  <MemberRow>
                    <MemberName>{item.fullName}</MemberName>
                    <div>{item.phone}</div>
                  </MemberRow>
                  <MemberRow>{item.address}</MemberRow>
                  <MemberRow>
                    <MemberBtnGroup>
                      <EditBtn onClick={() => handleClickEdit(item)}>
                        編輯
                      </EditBtn>
                      <DeleteBtn onClick={() => handleClickDelete(item)}>
                        刪除
                      </DeleteBtn>
                    </MemberBtnGroup>
                  </MemberRow>
                </Member>
              );
            })}
          </MemberList>
        ) : (
          <Skeleton
            containerClassName="skeleton-member-list-wrapper"
            className="skeleton-member-list"
            count={10}
            inline
          />
        )}
      </Container>
    </Layout>
  );
};

export default ReceiverList;
